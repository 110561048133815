<template>
    <div class="ToolClassList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="annexFram" ref="annexFram">
                    <el-form-item label="附件名称">
                        <el-input v-model="annexFram.spareName" placeholder="请输入附件名称查询"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button class="btn" @click="addAnnexInfo" icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="annexList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="spareCode" label="附件编号" align="center"> </el-table-column>
                <el-table-column prop="spareName" label="附件名称" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="annexFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="annexFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!-- 新增表单 -->
        <el-dialog :title="state ? '添加附件' : '修改附件'" :visible.sync="dialogFormVisible" width="500px"
            :before-close="closeInfo">
            <el-form :model="annexInfo" :rules="rules" ref="annexInfo">
                <el-form-item label="附件编号" :label-width="formLabelWidth" prop="spareCode" v-show="!state">
                    <el-input v-model="annexInfo.spareCode" autocomplete="off" :disabled="!state" ></el-input>
                </el-form-item>
                <el-form-item label="附件名称" :label-width="formLabelWidth" prop="spareName">
                    <el-input v-model="annexInfo.spareName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="annexInfo.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeInfo('annexInfo')">取 消</el-button>
                <el-button class="btn" @click="sure('annexInfo')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { serchAnnexInfo, delAnnexInfo, addAnnexInfo, updateAnnexInfo } from '@/api/annex.js'
export default {
    data() {
        return {
            annexList: [],
            annexFram: {
                spareName: null,
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            annexInfo: {
                id: null,
                spareCode: null,
                spareName: null,
                remark: null
            },
            rules: {
                spareName: [{ required: true, message: '请输入附件名称' }],
            },
            formLabelWidth: '80px',
            dialogFormVisible: false,
            state: false
        }
    },
    mounted() {
        this.loadAnnexInfo();
    },
    methods: {
        handleSizeChange(val) {
            this.annexFram.pageSize = val;
            this.annexFram.pageNum = 1;
            this.loadAnnexInfo();
        },
        handleCurrentChange(val) {
            this.annexFram.pageNum = val;
            this.loadAnnexInfo();
        },

        loadAnnexInfo() {
            serchAnnexInfo(this.annexFram).then(res => {
                if (res.code === '000000') {
                    this.annexList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.annexList.forEach((item) => {
                        item.seq = count++
                    })
                }
            })
        },
        //新增
        addAnnexInfo() {
            this.annexInfo = {
                id: null,
                spareCode: null,
                spareName: null,
                remark: null
            },
                this.dialogFormVisible = true
            this.state = true
        },
        // 修改
        handleEdit(row) {
            this.state = false;
            this.dialogFormVisible = true;
            //不要将当前行的值直接赋值给表单
            // this.form = row;
            this.annexInfo = { ...row };
        },

        //提交
        sure(annexInfo) {
            this.$refs[annexInfo].validate(valid => {
                if (valid) {
                    if (this.state) {
                        //新增
                        addAnnexInfo(this.annexInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                            }

                            this.$refs[annexInfo].resetFields()
                            this.dialogFormVisible = false

                            this.loadAnnexInfo()
                        })
                    } else {
                        //修改
                        updateAnnexInfo(this.annexInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                            }
                            this.$refs[annexInfo].resetFields()
                            this.dialogFormVisible = false
                            this.loadAnnexInfo()
                        })

                    }

                }
            })
        },

        //关闭
        closeInfo() {
            this.$refs['annexInfo'].resetFields();
            this.dialogFormVisible = false;
        },

        //删除
        del({id,spareName}) {
            this.$confirm('是否删除名称为“'+spareName+'”的附件？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delAnnexInfo(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.loadAnnexInfo()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },


        //搜索
        handleSearch() {
            this.loadAnnexInfo(this.annexFram);
        },
        //重置
        handleReset() {
            this.annexFram = {};
            this.loadAnnexInfo(this.annexFram);
        }


    }
}

</script>

<style lang="scss" scoped>
.ToolClassList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>