import service from "../service.js";

export const serchAnnexInfo = params => {
    return service({
      method: 'POST',
      url: '/annexInfo/serchAnnexInfo',
      params
    })
}

export const getAnnexInfos = () => {
    return service({
      method: 'GET',
      url: '/annexInfo/getAnnexInfos',
    })
}


export const delAnnexInfo = (id) => {
    return service({
      method: 'GET',
      url: '/annexInfo/delAnnexInfo',
      params: {
        id
      }
    })
  }

export const addAnnexInfo = (data) => {
  return service({
    method: 'POST',
    url: '/annexInfo/addAnnexInfo',
    data
  })
}

export const updateAnnexInfo = (data) => {
  return service({
    method: 'POST',
    url: '/annexInfo/updateAnnexInfo',
    data
  })
}


